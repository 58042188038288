import { ChevronLeftIcon } from '@heroicons/react/24/outline'

export const NavBar = ({onBack, title}) => (
  <div className="relative">
    <div className="absolute inset-x-0 pt-1">
      <h1 className="text-2xl text-center">{title}</h1>
    </div>
    <div className="flex relative z-10">
      <button
        className="bg-gray-100 rounded-full p-2 flex space-x-1 pr-4 items-center text-gray-500"
        onClick={onBack}
      >
        <ChevronLeftIcon className="w-6 h-6"/>
        <span className="font-medium">Exit</span>
      </button>
    </div>
  </div>
)