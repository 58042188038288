export const ActionButtons = ({
  hasActionsRemaining,
  onEndTurn,
  onPickTopping,
  onPickBase
}) => {
  return hasActionsRemaining ? (
    <div className="gap-x-4 grid grid-cols-2 my-4 ">
      <button
        onClick={() => onPickTopping()}
        className="rounded-xl p-6 px-4 font-medium bg-yellow-600 text-white"
      >
        Pick up topping
      </button>

      <button
        onClick={() => onPickBase()}
        className="rounded-xl p-6 px-4 font-medium bg-green-600 text-white"
      >
        Pick up base
      </button>
    </div>
  ) : (
    <button
      onClick={() => onEndTurn()}
      className="my-4 w-full rounded-xl p-6 px-4 font-medium bg-blue-600 text-white"
    >
      End your turn
    </button>
  )
}