import { NavBar } from "../components/NavBar"
import { useNavigate } from 'react-router-dom'
import { Button } from "../components/Button"

export const HowToPlay = () => {
  const navigate = useNavigate()

  return (
    <div>
      <NavBar
        title="How to play"
        onBack={() => navigate('/')}
      />

      <div className="mt-4 space-y-4">
        <h2 className="font-medium text-xl">
          Collect toppings and bases to bake pizzas.
        </h2>

        <h3 className="text-lg">Earn points for baking pizzas. The more toppings on the pizza, the more points you get!</h3>

        <h4 className="text-lg font-semibold">The player with the most points wins!</h4>

        <p>
          Each player gets <span className="font-medium">ten</span> turns, and you're allowed to make <span className="font-medium">two</span> actions per turn.
        </p>

        <p>
          All pizzas require a base in order to bake it.
        </p>

        <p>
          <span className="font-medium">Watch out!</span> When the game is over, you'll lose one point for each leftover topping or base.
        </p>

        <div className="flex flex-col">
          <Button
            onClick={() => navigate('/')}
          >
            Okay, I get it
          </Button>
        </div>
      </div>
    </div>
  )
}