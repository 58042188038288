import { push, ref } from "firebase/database";
import { useMemo, useState } from "react";
import { database } from "./App";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "./components/Button";
import { addPlayerToGame } from "./utils/GameUtils";

export const JoinGame = () => {
  const { gameId } = useParams()
  const [yourName, setName] = useState(localStorage.getItem('name') ?? '')
  const navigate = useNavigate()
  const gameRef = useMemo(() => ref(database, 'games/' + gameId + '/players'), [gameId])

  const addPlayer = async () => {
    const added = await push(gameRef, addPlayerToGame(yourName))

    localStorage.setItem('name', yourName)
    localStorage.setItem('playerId', added.key)

    navigate('/lobby/' + gameId)
  }

  return (
    <div className="space-y-4 flex flex-col">
      <h1>What's your name?</h1>
      <input
        type="text"
        value={yourName}
        onChange={e => setName(e.target.value)}
        className="border border-gray-300 rounded-lg p-3"
        autoFocus
      />
      <Button onClick={addPlayer}>Go go go</Button>
    </div>

  )
}