export const CurrentPlayerToppings = ({ toppings, isCurrent }) => (
  <div>
    <div className="text-lg font-medium">{isCurrent ? 'Your' : 'Their'} toppings</div>

    <ul className="grid grid-cols-4 gap-2 mt-1">
      {toppings.map((topping, index) => (
        <li className="p-1 text-center bg-gray-50 rounded-lg" key={index}>
          <div>{topping.emoji}</div>
          <div className="text-xs">{topping.name}</div>
        </li>
      ))}

      {toppings.length === 0 && (
        <li className="col-span-4 text-sm text-gray-500">{isCurrent ? 'You' : 'They'} haven't got any toppings yet.</li>
      )}
    </ul>
  </div>
)