export const CurrentPlayerBases = ({baseCount, isCurrent}) => (
  <div>
    <div className="text-lg font-medium">{isCurrent ? 'Your' : 'Their'} bases</div>
    <ul className="grid grid-cols-4 gap-2 mt-1">
      {[...Array(baseCount)].map((_, index) => (
        <li className="p-1 text-center bg-gray-50 rounded-lg" key={index}>
          <div>🍞</div>
        </li>
      ))}

      {baseCount === 0 && (
        <li className="col-span-4 text-sm text-gray-500">{isCurrent ? 'You' : 'They'} haven't got any bases yet.</li>
      )}
    </ul>
  </div>
)