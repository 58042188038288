import { push, ref, set, update } from "firebase/database";
import { database } from "./App";
import { Button } from "./components/Button";
import { useNavigate } from 'react-router-dom'
import { addPlayerToGame, makeNewGame } from "./utils/GameUtils";

export const MainMenu = () => {
  const navigate = useNavigate()

  const createGame = async (name) => {
    // Create a new game hash.
    const gameHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    await set(ref(database, 'games/' + gameHash), makeNewGame())

    // Now push a new player in.
    const gameRef = ref(database, 'games/' + gameHash + '/players')

    const added = await push(gameRef, addPlayerToGame(name))

    localStorage.setItem('playerId', added.key)
    localStorage.setItem('hostingGame', gameHash)
  
    return ref(database, 'games/' + gameHash)
  }

  const hostGame = async () => {
    const name = prompt('What is your name?');

    if (! name) {
      alert('Oi, give us your name')

      return
    }

    const ref = await createGame(name)

    navigate('/lobby/' + ref.key)
  }

  return (
    <div>
      <div className="mx-auto text-center border-b border-dashed border-gray-300 mb-6 pb-3">
        <div className="text-3xl">🍕</div>
        <div className="mt-2 text-lg font-medium tracking-tight">Pizza Stop</div>
      </div>

      <div className="flex flex-col space-y-3">
        <Button
          onClick={hostGame}>
          Host game
        </Button>

        <Button
          onClick={() => navigate('/how-to-play')}
          variant="secondary"
        >
          How to play
        </Button>
      </div>
    </div>
  )
}