import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { MainMenu } from './MainMenu';
import { JoinGame } from './JoinGame';
import { Layout } from './components/Layout';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Game } from './screens/Game';
import { Lobby } from './screens/Lobby';
import { HowToPlay } from './screens/HowToPlay';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig: Firebase = {
  apiKey: "AIzaSyAFCxrIJqPp3DtxmQOP3VSAORkLGnb7XJk",
  authDomain: "pizza-stop-7cabc.firebaseapp.com",
  projectId: "pizza-stop-7cabc",
  storageBucket: "pizza-stop-7cabc.appspot.com",
  messagingSenderId: "904552223331",
  appId: "1:904552223331:web:fd8fa52779b5c92f165e56",
  databaseURL: "https://pizza-stop-7cabc-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

if (process.env.NODE_ENV === 'development') {
  connectDatabaseEmulator(database, "localhost", 9000);
}

function App() {
  const q = new URLSearchParams(window.location.search)

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/join-game/:gameId" element={<JoinGame/>}/>
          <Route path="/lobby/:gameId" element={<Lobby/>}/>
          <Route path="/play/:gameId" element={<Game/>}/>
          <Route path="/how-to-play" element={<HowToPlay/>}/>
          <Route path="/" element={<MainMenu/>}/>
        </Routes>
      </Layout>
    </Router>
  )

}

export default App;
