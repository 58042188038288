export const Button = ({
  onClick,
  children,
  disabled,
  variant = 'primary'
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`p-3 font-medium rounded-lg border ${variant === 'primary' ? 'border-transparent bg-green-500 text-white' : 'border-green-500 text-green-500'} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {children}
  </button>
)