import { shuffleDeck } from '../Deck.ts'

export const makeNewGame = () => ({
  createdAt: Date.now(),
  startedAt: null,
  players: [],
  currentPlayer: null,
  gameOver: false,
  deck: shuffleDeck(),
})

export const addPlayerToGame = (name) => ({
  name,
  remainingActions: 2,
  remainingTurns: 10,
  toppingsInHand: [],
  cookedPizzas: [],
  baseCardsInHand: 0,
})
