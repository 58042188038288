import { useEffect, useMemo, useState } from 'react';
import toppings from '../Toppings.json'
import pizzas from '../Pizzas.json'
import { CurrentPlayerToppings } from '../CurrentPlayerToppings';
import { CurrentPlayerBases } from '../CurrentPlayerBases';
import { ActionButtons } from '../ActionButtons';
import { CurrentPlayerSummary } from '../CurrentPlayerSummary';
import { PlayerSetup } from '../PlayerSetup';
import { shuffleDeck } from '../Deck.ts';
import { useParams } from 'react-router-dom'
import { onValue, ref, update } from 'firebase/database';
import { database } from '../App';
import { Button } from '../components/Button';
import { useNavigate } from 'react-router-dom'

export const Game = () => {
  const { gameId } = useParams()
  const gameRef = useMemo(() => ref(database, 'games/' + gameId), [gameId])
  const [currentPlayerId, setCurrentPlayerId] = useState(null)
  const isCurrentPlayer = useMemo(() => currentPlayerId === localStorage.getItem('playerId'), [currentPlayerId])
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const currentPlayerRef = useMemo(() => ref(database, 'games/' + gameId + '/players/' + currentPlayerId), [gameId, currentPlayerId])
  const [players, setPlayers] = useState(null)
  const [deck, setDeck] = useState([])
  const [gameOver, setGameOver] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (gameRef && currentPlayerRef) {
      onValue(currentPlayerRef, snapshot => setCurrentPlayer(snapshot.val()))
    }
  }, [gameRef, currentPlayerRef])

  useEffect(() => {
    if (gameRef) {
      onValue(gameRef, snapshot => {
        const data = snapshot.val()

        setDeck(data.deck)
        setPlayers(data.players)
        setCurrentPlayerId(data.currentPlayer)

        // If all the players have 0 turns left and 0 actions,
        // the game is over.
        console.log(Object.values(data.players))

        if (Object.values(data.players).every(player => player.remainingTurns <= 0 && player.remainingActions <= 0)) {
          console.log('game over!!!')
          setGameOver(true)
        }
      })
    }
  }, [gameRef])

  const moveToNextPlayer = async () => {
    const playerIds = Object.keys(players)
    const currentPlayerIndex = playerIds.indexOf(currentPlayerId)
    const nextPlayerIndex = currentPlayerIndex + 1 >= playerIds.length ? 0 : currentPlayerIndex + 1
    const nextPlayerId = playerIds[nextPlayerIndex]

    await update(currentPlayerRef, {
      remainingActions: currentPlayer.remainingTurns > 1 ? 2 : 0,
      remainingTurns: currentPlayer.remainingTurns - 1,
    })

    await update(gameRef, {
      currentPlayer: nextPlayerId,
    })
  }

  const takeToppingCard = () => {
    const newDeck = [...deck]
    const card = newDeck.pop()

    update(gameRef, {
      deck: newDeck
    })

    return card
  }

  const receiveToppingCardForCurrentPlayer = () => {
    const topping = takeToppingCard()

    // The new toppings array should be the previous, with the new card appended.
    const newToppings = [...(currentPlayer.toppingsInHand ?? []), topping]

    // Update the toppings array for the current player.
    update(currentPlayerRef, {
      toppingsInHand: newToppings
    })

    completeActionForCurrentPlayer()
  }

  const hasToppingsForPizza = (pizza) => {
    return pizza.requires.every(toppingId => (currentPlayer.toppingsInHand ?? []).some(topping => topping.id === toppingId))
  }

  const canBakePizza = (pizza) => {
    return hasToppingsForPizza(pizza) &&
      currentPlayer.baseCardsInHand > 0
  }

  const receiveBaseCardForCurrentPlayer = async () => {
    const newBaseCards = currentPlayer.baseCardsInHand + 1

    await update(currentPlayerRef, {
      baseCardsInHand: newBaseCards
    })

    completeActionForCurrentPlayer()
  }

  const bakePizza = async (pizza) => {
    // Does the current player have the required toppings?
    if (! canBakePizza(pizza)) {
      console.error('You haven\'t got the required toppings to bake this pizza!')
    }

    const removedToppings = []
    const updatedToppingsInHand = currentPlayer.toppingsInHand.filter(topping => {
      // Only remove it if we haven't already removed it.
      if (pizza.requires.includes(topping.id) && ! removedToppings.includes(topping.id)) {
        removedToppings.push(topping.id)
        return false
      }

      return true
    })

    const updatedBaseCardsInHand = currentPlayer.baseCardsInHand - 1

    const updatedCookedPizzas = [...(currentPlayer.cookedPizzas ?? []), pizza]

    // Remove the toppings from the player's hand.
    await update(currentPlayerRef, {
      toppingsInHand: updatedToppingsInHand,
      baseCardsInHand: updatedBaseCardsInHand,
      cookedPizzas: updatedCookedPizzas,
    })

    completeActionForCurrentPlayer()
  }

  const calculatePoints = (player) => {
    // Add up all the cooked pizza points.
    const points = (player.cookedPizzas ?? []).reduce((total, pizza) => {
      return total + pizza.points
    }, 0)

    // Subtract any leftover toppings and bases
    return points - (player.toppingsInHand.length + player.baseCardsInHand)
  }

  const completeActionForCurrentPlayer = () => {
    let remainingActions = currentPlayer.remainingActions

    if (currentPlayer.remainingActions >= 1) {
      remainingActions--
    }

    update(currentPlayerRef, {
      remainingActions
    })
  }

  const handleEndGame = () => {
    navigate('/');
  }

  if (!currentPlayer) {
    return <div>Loading</div>
  }

  if (gameOver) {
    return (
      <div className="m-3 p-3 rounded-lg">
        <h1 className="text-lg">Game over</h1>

        <ul className="space-y-4">
          {Object.values(players).map(player => (
            <li key={player.id}>
              <div className="text-lg font-medium">
                {player.name} scored {calculatePoints(player)}
              </div>

              <ul className="pl-4 mt-2">
                {(player.cookedPizzas ?? []).map(pizza => (
                  <li key={pizza.id}>
                    <div className="text-sm">
                      <span className="font-medium">{pizza.name}</span> +{pizza.points} points
                    </div>
                  </li>
                ))}

                {player.toppingsInHand.map(topping => (
                  <li key={`topping_${topping.id}`}>
                    <div className="text-sm">
                      <span className="font-medium">Leftover {topping.name.toLowerCase()}</span> -1 point
                    </div>
                  </li>
                ))}

                {player.baseCardsInHand > 0 && (
                  <li key="leftover_base">
                    <div className="text-sm">
                      <span className="font-medium">Leftover base x{player.baseCardsInHand}</span> -{player.baseCardsInHand} points
                    </div>
                  </li>
                )}

                {player.toppingsInHand.length === 0 && player.baseCardsInHand === 0 && (
                  <li>
                    <div className="text-sm font-medium">No leftovers! 🥳</div>
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>

        <div className="mt-4 flex flex-col">
          <Button
            onClick={handleEndGame}
          >
            End game
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="p-3">
      <div className="space-y-6">
        <CurrentPlayerSummary
          isCurrent={isCurrentPlayer}
          currentPlayer={currentPlayer}
        />

        <CurrentPlayerToppings
          isCurrent={isCurrentPlayer}
          toppings={(currentPlayer.toppingsInHand ?? [])}
        />

        <CurrentPlayerBases
          isCurrent={isCurrentPlayer}
          baseCount={currentPlayer.baseCardsInHand}
        />
      </div>

      {isCurrentPlayer && (
        <ActionButtons
          hasActionsRemaining={currentPlayer.remainingActions > 0}
          onEndTurn={() => moveToNextPlayer()}
          onPickTopping={() => receiveToppingCardForCurrentPlayer()}
          onPickBase={() => receiveBaseCardForCurrentPlayer()}
        />
      )}

      {isCurrentPlayer && (
        <div>
          <div className="text-2xl font-bold">Oven</div>

          {/* <div className="flex space-x-2 overflow-x-scroll py-3"> */}
          <div className="grid grid-cols-2 gap-3">
            {pizzas.map(pizza => (
              <div key={`pizza_${pizza.id}`} className="aspect-square text-left rounded-xl bg-gray-50 p-3 flex flex-col">
                <div className="flex-shrink-0">
                  <div className="font-medium truncate">{pizza.name}</div>
                  <div className="text-sm text-gray-500">for {pizza.points} points</div>
                </div>

                <div className="text-sm text-gray-500 space-x-1">Requires{' '}{
                  pizza.requires.map((toppingId, index) => (
                    <span
                      key={`pizza_${pizza.id}_requires_${index}`}
                      className={`underline decoration-2 underline-offset-4 ${(currentPlayer.toppingsInHand ?? []).some(topping => topping.id === toppingId) ? 'decoration-green-500' : 'decoration-red-500'}`}
                    >
                      {toppings.find(topping => topping.id === toppingId).emoji}
                    </span>
                  ))
                }</div>

                <div className="flex-shrink-0">
                  {canBakePizza(pizza) && currentPlayer.remainingActions > 0 && (
                    <button
                      onClick={() => bakePizza(pizza)}
                      className="p-3 px-4 rounded-xl mt-4 font-medium bg-gray-600 text-white disabled:opacity-50 w-full"
                    >
                      Bake
                    </button>
                  )}

                  {(!hasToppingsForPizza(pizza)) && (
                    <div className="text-sm py-3">You don't have the required toppings.</div>
                  )}

                  {(!canBakePizza(pizza)) && hasToppingsForPizza(pizza) && (
                    <div className="text-sm py-3">You've got the toppings but you're missing a base.</div>
                  )}

                  {canBakePizza(pizza) && currentPlayer.remainingActions === 0 && (
                    <div className="text-sm py-3">
                      You've got the required ingredients, but you've got no actions left this turn.
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}