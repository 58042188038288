import { onValue, ref, set, update } from "firebase/database"
import { useEffect, useMemo, useRef, useState } from "react"
import { database } from "../App"
import { Button } from "../components/Button"
import { useParams, useNavigate } from 'react-router-dom'
import QRCode from 'qrcode'
import { ChevronLeftIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { NavBar } from "../components/NavBar"

export const Lobby = () => {
  const { gameId } = useParams()
  const navigate = useNavigate()
  const [players, setPlayers] = useState([])
  const createdGame = useMemo(() => ref(database, 'games/' + gameId), [gameId])
  const isHost = localStorage.getItem('hostingGame') === gameId
  const canvas = useRef(null)
  const url = `https://pizza-stop-7cabc.web.app/join-game/${gameId}`

  useEffect(() => {
    if (gameId && canvas.current) {
      QRCode.toCanvas(canvas.current, url)
    }
  }, [gameId, canvas])

  useEffect(() => {
    if (createdGame) {
      onValue(createdGame, snapshot => {
        const data = snapshot.val()

        setPlayers(data.players)

        if (data.startedAt) {
          navigate('/play/' + gameId)
        }
      })
    }
  }, [createdGame, setPlayers])

  const startGame = () => {
    console.log(players)

    const playerIds = Object.keys(players)
    const randomPlayerId = playerIds[Math.floor(Math.random() * playerIds.length)]

    update(createdGame, {
      startedAt: Date.now(),
      currentPlayer: randomPlayerId,
    })
  }

  const exitLobby = () => {
    localStorage.removeItem('hostingGame')
    navigate('/')
  }

  const share = async () => {
    console.log(url)
    await navigator.share(url)
  }

  return (
    <div className="space-y-4 relative">
      <NavBar
        title="Lobby"
        onBack={exitLobby}
      />

      <div className="flex w-full justify-center mb-4">
        <canvas
          ref={canvas}
        />
      </div>

      <div className="text-center">
        <div>
          Invite friends to this game!
        </div>
        <div>
          <a className="text-green-500 hover:underline font-medium" href={url}>{url}</a>
        </div>
      </div>

      {/* {navigator.share && (
        <button onClick={share}>
          <ArrowUpOnSquareIcon className="w-6 h-6"/>
          <span>Share link</span>
        </button>
      )} */}

      <div className="mt-2 font-medium text-lg">Players in lobby</div>
      <ul>
        {Object.values(players).map((player, index) => (
          <li key={index}>{player.name}</li>
        ))}
      </ul>

      <div className="flex flex-col">
        {createdGame && (
          <Button
            disabled={!isHost || Object.keys(players).length < 2}
            onClick={startGame}
          >
            {(Object.keys(players).length < 2 ? "Need more players" : (isHost ? "Start game" : "Waiting for host..."))}
          </Button>
        )}
      </div>
    </div>
  )
}