import toppings from './Toppings.json'

export const shuffleDeck = () => {
  let deck: ToppingCard[] = []

  // Fill the deck up according to probability.
  toppings.forEach((topping: ToppingCard) => {
    for (let i = 0; i < (topping.probability * 3); i++) {
      deck.push(topping)
    }
  })

  // Now shuffle it.
  deck = deck.sort(() => (Math.random() > .5) ? 1 : -1);

  return deck;
}